import validation_imports from "./validation_imports"
import transform_error_list from "../transform_errors_list"
import { DEFAULT_ERROR } from "../../constants/validation/error_types"
import { ADMIN } from "../../constants/others_constants"

export default {
  ...validation_imports(ADMIN),
  validate() {
    // Clear error list and reset validator
    this.clear_errors("social-media-ads")

    // Defining validator without reactivity
    this.validator = true

    // If creating a campaign
    if (this.campaign_creation_modal) {
      if (!this.new_campaign.name) this.transforming_errors(
        DEFAULT_ERROR("social-media-ads", this.$translate("errors.social.missing_campaign_name"))
      )
      if (!this.new_campaign.objective) this.transforming_errors(
        DEFAULT_ERROR("social-media-ads", this.$translate("errors.social.missing_campaign_objective"))
      )
      if (!this.new_campaign.status) this.transforming_errors(
        DEFAULT_ERROR("social-media-ads", this.$translate("errors.social.missing_campaign_state"))
      )
    }

    // If creating an ad
    else {
      if (this.show_bid_cap_field && this.new_ad.bid_cap === "") this.transforming_errors(
        DEFAULT_ERROR("social-media-ads", this.$translate("errors.social.missing_bid_cap"))
      )
      if (!this.new_ad.name) this.transforming_errors(
        DEFAULT_ERROR("social-media-ads", this.$translate("errors.social.missing_ad_name"))
      )
      if (!this.new_ad.ad_link) this.transforming_errors(
        DEFAULT_ERROR("social-media-ads", this.$translate("errors.social.missing_ad_link"))
      )
      if (this.new_ad.ad_slides.length > 1 && this.new_ad.ad_slides.some(({ display_link }) => !display_link)) this.transforming_errors(
        DEFAULT_ERROR("social-media-ads", this.$translate("errors.social.missing_display_link"))
      )
      if (this.new_ad.ad_slides.some(({ media }) => !media)) this.transforming_errors(
        DEFAULT_ERROR("social-media-ads", this.$translate("errors.social.missing_media"))
      )
      if (!this.selected_ad_accounts.length) this.transforming_errors(
        DEFAULT_ERROR("social-media-ads", this.$translate("errors.social.ad_account_not_selected"))
      )
      if (!this.selected_ad_campaigns.length) this.transforming_errors(
        DEFAULT_ERROR("social-media-ads", this.$translate("errors.social.ad_campaign_not_selected"))
      )
      if (!this.new_ad.selected_ad_page.id) this.transforming_errors(
        DEFAULT_ERROR("social-media-ads", this.$translate("errors.social.ad_fb_page_not_selected"))
      )
      if (
        this.selected_ad_accounts.length &&
        this.selected_ad_accounts.some(({ id }) => !this.new_ad.selected_audiences[id] || !this.new_ad.selected_audiences[id].length)
      ) this.transforming_errors(
        DEFAULT_ERROR("social-media-ads", this.$translate("errors.social.ad_fb_audience_not_selected"))
      )
    }
  
    return this.validator
  },
  transforming_errors(error, remove) {
    this.update_errors(transform_error_list(this.get_error_list(), error, remove, "collection-banners"))
    this.validator = false
  }
}
