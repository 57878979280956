<template>
  <section class="facebook-ad-fields">
    <md-content class="md-elevation-3">
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-large-size-25 md-medium-size-50 md-xsmall-size-100">
          <md-field>
            <label>{{ $translate('social_media_ads.display_link') }}</label>
            <md-input
              :value="ad_data.ad_link"
              @change="({ target }) => $emit('update_new_ad', 'ad_link', target.value)"
            />
          </md-field>
        </div>

        <div class="md-layout-item md-large-size-25 md-medium-size-50 md-xsmall-size-100">
          <md-field>
            <label for="fb_button">{{ $translate('social_media_ads.ad_button') }}</label>
            <md-select
              v-model="ad_data.fb_button"
              name="fb_button"
              id="fb_button"
              @md-selected="value => $emit('update_new_ad', 'fb_button', value)"
            >
              <md-option :value="''">{{ $translate(`social_media_ads.no_selection`) }}</md-option>
              <md-option
                v-for="button in fb_ad_buttons"
                :key="button"
                :value="button"
              >{{ $translate(`social_media_ads.ad_buttons.${button}`) }}</md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-large-size-25 md-medium-size-50 md-xsmall-size-100">
          <md-field>
            <label for="fb_ad_status">{{ $translate('social_media_ads.ad_status') }}</label>
            <md-select
              v-model="ad_data.fb_ad_status"
              name="fb_ad_status"
              id="fb_ad_status"
              @md-selected="value => $emit('update_new_ad', 'fb_ad_status', value)"
            >
              <md-option :value="''">{{ $translate(`social_media_ads.no_selection`) }}</md-option>
              <md-option
                v-for="status in fb_ad_statuses"
                :key="status"
                :value="status"
              >{{ $translate(`social_media_ads.ad_statuses.${status}`) }}</md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-large-size-25 md-medium-size-50 md-xsmall-size-100">
          <md-field>
            <label for="fb_billing_event">{{ $translate('social_media_ads.ad_billing_event') }}</label>
            <md-select
              v-model="ad_data.billing_event"
              name="fb_billing_event"
              id="fb_billing_event"
              @md-selected="value => $emit('update_new_ad', 'billing_event', value)"
            >
              <md-option :value="''">{{ $translate(`social_media_ads.no_selection`) }}</md-option>
              <md-option
                v-for="status in fb_ad_billing_events"
                :key="status"
                :value="status"
              >{{ $translate(`social_media_ads.billing_events.${status}`) }}</md-option>
            </md-select>
          </md-field>
        </div>
      </div>
    </md-content>

    <md-content class="md-elevation-3">
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-large-size-25 md-medium-size-50 md-xsmall-size-100">
          <md-field>
            <label>{{ $translate('social_media_ads.lifetime_budget') }}</label>
            <md-input
              :value="(ad_data.lifetime_budget || 0) / 100"
              @change="({ target }) => $emit('update_new_ad', 'lifetime_budget', Number(target.value) * 100)"
              type="number"
            />
            <span class="md-helper-text">{{ $translate('social_media_ads.fb_currency_message') }}</span>
          </md-field>
        </div>

        <div class="md-layout-item md-large-size-25 md-medium-size-50 md-xsmall-size-100">
          <md-field>
            <label>{{ $translate('social_media_ads.daily_budget') }}</label>
            <md-input
              :value="(ad_data.daily_budget || 0) / 100"
              @change="({ target }) => $emit('update_new_ad', 'daily_budget', Number(target.value) * 100)"
              type="number"
            />
            <span class="md-helper-text">{{ $translate('social_media_ads.fb_currency_message') }}</span>
          </md-field>
        </div>

        <div class="md-layout-item md-large-size-25 md-medium-size-50 md-xsmall-size-100">
          <p class="md-body-2">{{ $translate("social_media_ads.start_date") }}*</p>
          <Datetime v-model="start_date" type="datetime"/>
        </div>

        <div class="md-layout-item md-large-size-25 md-medium-size-50 md-xsmall-size-100">
          <p class="md-body-2">{{ $translate("social_media_ads.end_date") }}</p>
          <Datetime v-model="end_date" type="datetime"/>
        </div>
      </div>
    </md-content>

    <md-content class="md-elevation-3">
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-large-size-25 md-medium-size-50 md-xsmall-size-100">
          <md-field>
            <label for="promoted_object_event_type">{{ $translate('social_media_ads.promoted_object_event_type') }}</label>
            <md-select
              v-model="ad_data.promoted_object_event_type"
              name="promoted_object_event_type"
              id="promoted_object_event_type"
              @md-selected="value => $emit('update_new_ad', 'promoted_object_event_type', value)"
            >
              <md-option :value="''">{{ $translate(`social_media_ads.no_selection`) }}</md-option>
              <md-option
                v-for="event in fb_ad_event_types"
                :key="event"
                :value="event"
              >{{ $translate(`social_media_ads.ad_event_types.${event}`) }}</md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-large-size-25 md-medium-size-50 md-xsmall-size-100">
          <md-field>
            <label>{{ $translate('social_media_ads.pixel_id') }}</label>
            <md-input
              :value="ad_data.promoted_object_id"
              @change="({ target }) => $emit('update_new_ad', 'promoted_object_id', target.value)"
              type="number"
            />
          </md-field>
        </div>

        <div class="md-layout-item md-large-size-25 md-medium-size-50 md-xsmall-size-100">
          <md-field>
            <label>{{ $translate('social_media_ads.bid_cap') }}</label>
            <md-input
              :value="ad_data.bid_cap"
              @change="({ target }) => $emit('update_new_ad', 'bid_cap', target.value)"
              type="number"
              :disabled="!show_bid_cap_field"
            />
          </md-field>
        </div>
      </div>
    </md-content>
  </section>
</template>

<script>
import { Datetime } from "vue-datetime"
import { fb_ad_buttons, fb_ad_statuses, fb_ad_billing_events, fb_ad_event_types } from "../../../constants/social_media_constants"

export default {
  components: {
    Datetime,
  },
  props: {
    ad_data: {
      required: true,
      type: Object
    },
    show_bid_cap_field: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    start_date: {
      get() { return this.ad_data.start_date },
      set(value) { this.$emit('update_new_ad', 'start_date', value) }
    },
    end_date: {
      get() { return this.ad_data.end_date || new Date().toISOString() },
      set(value) { this.$emit('update_new_ad', 'end_date', value) }
    }
  },
  created() {
    this.fb_ad_buttons = fb_ad_buttons
    this.fb_ad_statuses = fb_ad_statuses
    this.fb_ad_billing_events = fb_ad_billing_events
    this.fb_ad_event_types = fb_ad_event_types
  }
}
</script>

<style lang="scss">
  @use "../../../../../../node_modules/vue-datetime/dist/vue-datetime.css";
</style>
