<template>
  <carousel
    :autoplay="false"
    :paginationEnabled="true"
    :navigationEnabled="false"
    :perPageCustom="[[300, 1], [650, 2], [900, 4]]"
    navigationNextLabel=" "
    navigationPrevLabel=" "
    class="social-media-ads__ad-slides"
  >
    <slide
      v-for="(ad, index) in slides"
      :key="`${index}_${ad.headline}`"
    >
      <div class="social-media-ads__ad-slide md-elevation-3">
        <md-button
          v-if="slides.length !== 1 && !Object.keys(slides[0]).length"
          class="md-icon-button md-accent md-raised social-media-ads__ad-slide-delete"
          @click="$emit('remove_slide', index)"
        >
          <md-icon>delete_outline</md-icon>
          <md-tooltip md-direction="top">{{ $translate("social_media_ads.remove_slide") }}</md-tooltip>
        </md-button>

        <div v-if="!ad.media" class="social-media-ads__ad-slide-top">
          <md-button class="md-icon-button" @click="$emit('open_media_upload')">
            <md-icon>insert_photo</md-icon>
            <md-tooltip md-direction="top">{{ $translate("social_media_ads.add_ad_images") }}</md-tooltip>
          </md-button>
          <md-button class="md-icon-button" @click="$emit('open_media_upload', true)">
            <md-icon>videocam</md-icon>
            <md-tooltip md-direction="top">{{ $translate("social_media_ads.add_ad_video") }}</md-tooltip>
          </md-button>
        </div>
        <div v-else class="social-media-ads__ad-slide-top">
          <img
            v-if="ad.media.type === 'photos'"
            class="image-upload__image"
            :src="ad.media.src"
          >
          <div v-else class="social-media-ads__media-preview">
            <video controls ref="video_upload">
              <source :src="ad.media.src">
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <md-list>
          <md-list-item>
            <md-field>
              <label>{{ $translate('social_media_ads.headline') }}</label>
              <md-input
                :value="slides[index].headline"
                md-autogrow
                :placeholder="$translate('social_media_ads.headline')"
                @change="({ target }) => $emit('update_slide', index, 'headline', target.value)"
              />
            </md-field>
          </md-list-item>

          <md-list-item>
            <md-field>
              <label>{{ $translate('social_media_ads.description') }}</label>
              <md-input
                :value="slides[index].description"
                md-autogrow
                :placeholder="$translate('social_media_ads.description')"
                @change="({ target }) => $emit('update_slide', index, 'description', target.value)"
              />
            </md-field>
          </md-list-item>

          <md-list-item v-if="slides.length > 1">
            <md-field>
              <label>{{ $translate('social_media_ads.display_link') }}</label>
              <md-input
                :value="slides[index].display_link"
                md-autogrow
                :placeholder="$translate('social_media_ads.display_link')"
                @change="({ target }) => $emit('update_slide', index, 'display_link', target.value)"
              />
            </md-field>
          </md-list-item>
        </md-list>
      </div>
    </slide>
    <slide class="social-media-ads__ad-slides-new">
      <md-speed-dial md-direction="bottom">
        <md-speed-dial-target class="md-primary">
          <md-icon>add</md-icon>
        </md-speed-dial-target>

        <md-speed-dial-content>
          <md-button class="md-icon-button" @click="$emit('open_media_upload', false, true)">
            <md-icon>insert_photo</md-icon>
            <md-tooltip md-direction="top">{{ $translate("social_media_ads.add_ad_images") }}</md-tooltip>
          </md-button>

          <md-button class="md-icon-button" @click="$emit('open_media_upload', true, true)">
            <md-icon>videocam</md-icon>
            <md-tooltip md-direction="top">{{ $translate("social_media_ads.add_ad_video") }}</md-tooltip>
          </md-button>
        </md-speed-dial-content>
      </md-speed-dial>
    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from "../../../../Shared/components/slider/index"

export default {
  components: {
    Carousel,
    Slide,
  },
  props: {
    slides: {
      required: true,
      type: Array
    }
  }
}
</script>
