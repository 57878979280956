<template>
  <section class="social-media social-media-ads scroll-content">
    <h3 class="md-display-1">{{ $translate("social_media_ads.title") }}:</h3>

    <section_description_preview>
      <div class="md-subheading">{{ $translate("social_media_ads.message") }}</div>
    </section_description_preview>

    <md-tabs
      v-if="mounted && is_global_admin"
      :md-active-tab="active_tab"
      @md-changed="tab_idnex => { active_tab = tab_idnex; open_emoji_selector = false }"
      class="social-media-ads__sections full-width-tabs-navigation"
    >

      <!-- Creating Ad -->

      <md-tab
        id="0"
        :md-label="$translate('social_media_ads.creating_ads')"
        :style="{ height: active_tab === '0' ? 'auto' : '0', overflow: active_tab === '0' ? 'visible' : 'hidden' }"
      >
        <div class="md-layout md-gutter md-alignment-center-space-between">
          <div class="md-layout-item md-size-50 md-small-size-100">
            <h4 class="md-headline">{{ $translate("social_media_ads.create_ad_title") }}:</h4>
          </div>
          <div class="md-layout-item md-size-33 md-layout md-alignment-center-right social-media-ads__clear-top">
            <md-button class="md-raised md-accent" @click="clear_ad_data" :disabled="!permissions.social.sections.advertising.manage">
              {{ $translate("social_media_ads.clear_ad") }}
            </md-button>
          </div>
        </div>

        <div class="social-media-ads__creation">
          <div>
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-size-33 md-small-size-50 md-xsmall-size-100">
                <md-field>
                  <label>{{ $translate('social_media_ads.ad_name') }}*</label>
                  <md-input v-model="new_ad.name"/>
                </md-field>
              </div>
            </div>
            <div class="social-media-ads__creation-top">
              <div>
                <md-button class="md-icon-button md-fab md-plain" @click="open_entity_selection_modal = true">
                  <products_icon />
                  <md-tooltip md-direction="top">{{ $translate("social_media_ads.create_from_product") }}</md-tooltip>
                </md-button>
              </div>
              <div>
                <md-field>
                  <label>{{ $translate('social_media_ads.primary_text') }}</label>
                  <md-textarea v-model="new_ad.ad_message" ref="post_textarea"/>
                </md-field>
                <div class="social-media-ads__actions">
                  <div class="social-media-ads__emoji-button">
                    <md-button class="md-icon-button" @click="() => open_emoji_selector = !open_emoji_selector">
                      <md-icon>sentiment_satisfied_alt</md-icon>
                      <md-tooltip md-direction="top">{{ $translate("social_media_ads.add_ad_emoji") }}</md-tooltip>
                    </md-button>
                    <Emoji_picker v-if="open_emoji_selector" @select="add_emoji"/>
                  </div>
                </div>
              </div>
            </div>

            <ad_creation_carousel
              :slides="new_ad.ad_slides"
              @open_media_upload="open_media_upload"
              @update_slide="update_slide"
              @remove_slide="remove_add"
            />
            
            <entity_selection_modal
              :open_modal="open_entity_selection_modal"
              :should_fetch_data="true"
              @entity_selected="select_entity"
              @toggle_modal="val => open_entity_selection_modal = val"
            />

            <facebook_ad_fields
              :ad_data="new_ad"
              :show_bid_cap_field="show_bid_cap_field"
              @update_new_ad="update_new_ad"
            />

            <md-dialog :md-active.sync="open_media_upload_modal">
              <md-dialog-title>
                {{ $translate(`social_media_posts.upload_${uploading_video ? "video" : "image"}`) }}
              </md-dialog-title>

              <div class="md-elevation-5 image-upload">
                <input
                  v-if="uploading_video"
                  v-bind="media_upload_attributes"
                  @drop="event => add_media_files(event.target.files)"
                  @input="event => add_media_files(event.target.files)"
                  v-cloak
                >
                <input
                  v-else
                  v-bind="media_upload_attributes"
                  multiple
                  @drop="event => add_media_files(event.target.files)"
                  @input="event => add_media_files(event.target.files)"
                  v-cloak
                >
                <label for="image-upload">
                  <md-icon>cloud_upload</md-icon>
                  {{ $translate(`drag_and_drop_${uploading_video ? "video" : "image"}`) }}
                </label>
              </div>
            </md-dialog>
          </div>

          <right_ad_creation_panel
            :ad_provider_errors="ad_provider_errors"
            :selected_ad_accounts="selected_ad_accounts"
            :selected_ad_campaigns="selected_ad_campaigns"
            :ad_data="new_ad"
            :get_all_ad_accounts="get_all_ad_accounts"
            :can_user_magade_ads="permissions.social.sections.advertising.manage"
            @select_ad_entity="select_ad_entity"
            @open_campaign_creation_modal="(ad_account_id) => { campaign_creation_modal = true; selected_ad_account = ad_account_id }"
            @update_new_ad="update_new_ad"
            @publish_ad="publish_ad"
          />

          <md-dialog :md-active.sync="campaign_creation_modal" class="social-media-ads__create-campaign">
            <md-dialog-title>
              {{ $translate("social_media_ads.create_new_campaign") }}
            </md-dialog-title>

            <md-dialog-content>
              <md-field>
                <label>{{ $translate('social_media_ads.campaign_name') }}*</label>
                <md-input v-model="new_campaign.name" />
              </md-field>
              <div class="md-layout md-gutter">
                <div class="md-layout-item">
                  <md-field>
                    <label for="fb_ad_objective">{{ $translate('social_media_ads.campaign_objective') }}*</label>
                    <md-select v-model="new_campaign.objective" name="fb_ad_objective" id="fb_ad_objective">
                      <md-option
                        v-for="objective in fb_ad_objectives"
                        :key="objective"
                        :value="objective"
                      >{{ $translate(`social_media_ads.objectives.${objective}`) }}</md-option>
                    </md-select>
                  </md-field>
                </div>

                <div class="md-layout-item">
                  <md-field>
                    <label for="fb_ad_status">{{ $translate('social_media_ads.campaign_status') }}*</label>
                    <md-select v-model="new_campaign.status" name="fb_ad_status" id="fb_ad_status">
                      <md-option
                        v-for="status in fb_ad_statuses"
                        :key="status"
                        :value="status"
                      >{{ $translate(`social_media_ads.ad_statuses.${status}`) }}</md-option>
                    </md-select>
                  </md-field>
                </div>
              </div>
              <div class="md-layout md-gutter">
                <div class="md-layout-item">
                  <md-field>
                    <label>{{ $translate('social_media_ads.lifetime_budget') }}</label>
                    <md-input
                      :value="(new_campaign.lifetime_budget || 0) / 100"
                      @change="({ target }) => new_campaign.lifetime_budget = Number(target.value) * 100"
                      type="number"
                    />
                    <span class="md-helper-text">{{ $translate('social_media_ads.fb_currency_message') }}</span>
                  </md-field>
                </div>

                <div class="md-layout-item">
                  <md-field>
                    <label>{{ $translate('social_media_ads.daily_budget') }}</label>
                    <md-input
                      :value="(new_campaign.daily_budget || 0) / 100"
                      @change="({ target }) => new_campaign.daily_budget = Number(target.value) * 100"
                      type="number"
                    />
                    <span class="md-helper-text">{{ $translate('social_media_ads.fb_currency_message') }}</span>
                  </md-field>
                </div>
              </div>
            </md-dialog-content>

            <md-dialog-actions>
              <md-button class="md-primary" @click="clear_campaign_creation">{{ $translate('close') }}</md-button>
              <md-button class="md-primary" @click="create_new_campaign">{{ $translate('save') }}</md-button>
            </md-dialog-actions>
          </md-dialog>
        </div>

        <div class="md-layout-item md-size-33 md-layout md-alignment-center-right social-media-ads__clear-bottom">
          <md-button class="md-raised md-accent" @click="clear_ad_data" :disabled="!permissions.social.sections.advertising.manage">
            {{ $translate("social_media_ads.clear_ad") }}
          </md-button>
        </div>
      </md-tab>

      <!-- Previewing ads section -->

      <md-tab
        id="1"
        :md-label="$translate('social_media_ads.previewing_ads')"
        :style="{ height: active_tab === '1' ? 'auto' : '0', overflow: active_tab === '1' ? 'visible' : 'hidden' }"
      >
        <div>
          <p class="md-title">{{ $translate("social_media_ads.ad_channels_sharing") }}:</p>
          <md-tabs :md-active-tab="chosen_account_id" @md-changed="change_ad_account">
            <md-tab
              v-for="{ id, name } in get_all_ad_accounts"
              :key="id"
              :id="id"
              :md-label="name"
            />
          </md-tabs>

          <div v-if="correct_ads.length" class="social-media-ads__ads">
            <md-table v-model="correct_ads" class="social-media-ads__table admin-table">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell :md-label="$translate(`social_media_posts.table.created_time`)">{{
                  $moment(item.created_time, orders_API_date_format).format(date_time_format)
                }}</md-table-cell>
                <md-table-cell :md-label="$translate(`social_media_posts.table.name`)">{{ item.name }}</md-table-cell>
                <md-table-cell :md-label="$translate(`social_media_posts.table.image`)">
                  <span
                    v-for="(image_url, ind) in item.images"
                    :key="`${item.id}_${ind}`"
                  >
                    <img :src="image_url">
                  </span>
                </md-table-cell>
                <md-table-cell :md-label="$translate(`social_media_posts.table.is_published`)">
                  {{ item.status === fb_ad_statuse_names.ACTIVE ? "✅" : "❌" }}
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
          <p v-else-if="!correct_ads.length && this.chosen_account_id" class="md-subheading">
            {{ $translate("social_media_ads.no_ads_found") }}
          </p>
        </div>
      </md-tab>
    </md-tabs>
    <div v-else-if="!is_global_admin" class="social-media__admin-message">
      <h3 class="md-headline">
        {{ $translate("global_admin_specific_module") }}
        <a class="md-headline" :href="`https://${admin_domain}/admin/${project_config.project_id}/social/advertising`">Admin</a>
      </h3>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import { Picker } from "emoji-mart-vue"
import url_enity_encoder from "urlencode"
import { getThumbnails } from "video-metadata-thumbnails"
import { ADMIN, CONFIGURATION_STORE, SOCIAL_STORE, USER_STORE } from "../../../constants/others_constants"
import {
  CREATE_FACEBOOK_CAMPAIGN,
  FETCH_FACEBOOK_ADS,
  FETCH_FACEBOOK_AD_CAMPAIGNS,
  FETCH_FACEBOOK_AUDIENCES,
  PUBLISH_FACEBOOK_AD
} from "../../../stores/Admin/social/constants"
import {
  fb_ad_objectives, fb_ad_statuses, default_fb_campaign,
  fb_ad_statuse_names, fb_media_file_types, fb_bid_strategies_requiring_cap,
  supported_fb_image_formats, supported_fb_video_formats, default_new_ad_state
} from "../../../constants/social_media_constants"
import { collection, date_time_format, orders_API_date_format, product, SHARED_STORE } from "../../../../Shared/constants/other"
import validate from "../../../methods/validations/social_media_ads"
import products_icon from "../../../../Shared/components/icon_components/product-icon"
import entity_selection_modal from "../entity_selection_modal"
import get_curly_brace_content from "../../../../Shared/methods/get_curly_brace_content"
import get_correct_translation_value from "../../../../Shared/methods/get_correct_translation_value"
import { FETCH_AND_ASSING_MOMENT_LIBRARY, UPDATE_LOADER } from "../../../stores/Admin/constants"
import un_bind from "../../../../Shared/methods/un_bind"
import ad_creation_carousel from "./ad_creation_carousel"
import facebook_ad_fields from "./facebook_ad_fields"
import right_ad_creation_panel from "./right_ad_creation_panel"
import { admin_domain, image_dimension_names } from "../../../../../data/other_constants"
import get_image_src from "../../../../Shared/methods/get_image_src"
import section_description_preview from "../section_description_preview"

/**
 * !!!!!!!!!!!!!    IMPORTANT    !!!!!!!!!!!!!!!
 * 
 * For Facebook ads development, you must switch the Facebook
 * authentication App id and secret from "Ulsemo - localhost" to "Ulsemo" apps credentials.
 */

export default {
  components: {
    Emoji_picker: Picker,
    products_icon,
    entity_selection_modal,
    ad_creation_carousel,
    facebook_ad_fields,
    right_ad_creation_panel,
    section_description_preview
  },
  data() {
    return {
      new_ad: un_bind(default_new_ad_state),
      new_campaign: un_bind(default_fb_campaign),
      selected_ad_accounts: [],
      selected_ad_campaigns: [],
      ad_provider_errors: [],
      chosen_account_id: null,
      selected_ad_account: null,
      open_media_upload_modal: false,
      mounted: false,
      adding_new_slide: false,
      uploading_video: false,
      open_emoji_selector: false,
      open_entity_selection_modal: false,
      campaign_creation_modal: false,
      active_tab: "0",
      admin_domain
    }
  },
  computed: {
    ...mapState(SOCIAL_STORE, [
      "connected_fb_ad_accounts",
      "connected_fb_pages",
      "fb_ad_campaigns",
      "fb_ad_audiences",
      "ads"
    ]),
    ...mapState(ADMIN, ["$moment"]),
    ...mapState(CONFIGURATION_STORE, [
      "project_config",
      "analytics_config"
    ]),
    ...mapState(SHARED_STORE, ["is_global_admin"]),
    ...mapState(USER_STORE, ["permissions", "user_info"]),
    get_all_ad_accounts() {
      return [
        ...this.connected_fb_ad_accounts
      ]
    },
    correct_ads() {
      return this.ads[this.chosen_account_id] || []
    },
    media_upload_attributes() {
      return {
        type: "file",
        accept: [
          this.uploading_video ? supported_fb_video_formats : supported_fb_image_formats
        ].map(format => `${this.uploading_video ? "video" : "image"}/${format}`).join(",")
      }
    },
    show_bid_cap_field() {
      return this.selected_ad_campaigns.some(
        ({ bid_strategy }) => fb_bid_strategies_requiring_cap.includes(bid_strategy)
      )
    }
  },
  watch: {
    chosen_account_id(val) {
      if (val) this.fetch_facebook_ads(val)
    }
  },
  created() {
    this.update_loader()
    // Defining non-bound properties
    this.date_time_format = date_time_format
    this.orders_API_date_format = orders_API_date_format
    this.fb_ad_objectives = fb_ad_objectives
    this.fb_ad_statuses = fb_ad_statuses
    this.fb_ad_statuse_names = fb_ad_statuse_names
  },
  async mounted() {
    await this.fetch_and_assing_moment()
    this.mounted = true
    this.new_ad.promoted_object_id = this.analytics_config.fb_pixel_id || ""
    this.update_loader(false)
  },
  beforeDestroy() {
    this.open_emoji_selector = false
  },
  methods: {
    ...mapActions(SOCIAL_STORE, {
      publish_fb_ad: PUBLISH_FACEBOOK_AD,
      fetch_fb_ad_campaigns: FETCH_FACEBOOK_AD_CAMPAIGNS,
      create_fb_campaign: CREATE_FACEBOOK_CAMPAIGN,
      fetch_fb_ad_audiences: FETCH_FACEBOOK_AUDIENCES,
      fetch_facebook_ads: FETCH_FACEBOOK_ADS
    }),
    ...mapMutations(ADMIN, {
      update_loader: UPDATE_LOADER
    }),
    ...mapActions(ADMIN, {
      fetch_and_assing_moment: FETCH_AND_ASSING_MOMENT_LIBRARY
    }),
    ...validate,
    update_new_ad(key, value, sub_key) {
      if (sub_key) this.$set(this.new_ad[key], sub_key, value)
      else this.$set(this.new_ad, key, value)
    },
    update_slide(index, key, value) {
      this.$set(this.new_ad.ad_slides[index], key, value)
    },
    open_media_upload(uploading_video = false, adding_new_slide = false) {
      this.open_media_upload_modal = true;
      this.adding_new_slide = adding_new_slide
      this.uploading_video = uploading_video;
    },
    clear_campaign_creation() {
      this.new_campaign = un_bind(default_fb_campaign)
      this.campaign_creation_modal = false
      this.selected_ad_account = null
    },
    clear_ad_data() {
      this.new_ad = un_bind(default_new_ad_state)
      this.selected_ad_accounts = []
      this.selected_ad_campaigns = []
      this.ad_provider_errors = []
      this.selected_ad_account = null
    },
    async publish_ad() {
      if (!this.validate()) return

      // Returns array of campaign IDs whose ads werent created
      const unfinished_campaigns_ad_creations = await this.publish_fb_ad([
        {
          ...Object.entries(this.new_ad).reduce((tot, [key, value]) => ({
            ...tot,
            [key]: value === "" ? undefined : value // re-map to undefined if value doesnt exist
          }), {}),
          selected_audiences: Object.entries(this.new_ad.selected_audiences).reduce((tot, [key, value]) => ({
            ...tot,
            // Re-map full audience objects to selected_audiences
            [key]: value.map(audience_id => this.fb_ad_audiences[key].find(({ id }) => id === audience_id))
          }), {})
        },
        this.selected_ad_accounts,
        this.selected_ad_campaigns
      ])

      // If any campaign ads not finished, leave ad data just remove successful campaigns
      if (unfinished_campaigns_ad_creations.length) {
        this.selected_ad_campaigns = this.selected_ad_campaigns.filter(
          ({ id }) => unfinished_campaigns_ad_creations.map(({ id }) => id).includes(id)
        )
        this.ad_provider_errors = unfinished_campaigns_ad_creations

      }
      else this.clear_ad_data()
    },
    create_new_campaign() {
      if (!this.validate()) return

      this.create_fb_campaign([this.new_campaign, this.selected_ad_account])
      this.clear_campaign_creation()
    },
    get_ad_account_entities(entity_type) {
      const un_fetched_ad_accounts = this.selected_ad_accounts.filter(({ id }) => !this[`fb_ad_${entity_type}`][id])

      if (un_fetched_ad_accounts.length) this[`fetch_fb_ad_${entity_type}`](un_fetched_ad_accounts)
    },
    change_ad_account(id) {
      this.chosen_account_id = id
    },
    select_ad_entity(account, entity_type, fetched_entity_type) {
      if (
        this[`selected_ad_${entity_type}`].find(({ id }) => id === account.id)
      ) this[`selected_ad_${entity_type}`] = this[`selected_ad_${entity_type}`].filter(({ id }) => id !== account.id)
      else this[`selected_ad_${entity_type}`].push(account)


      // Filter out campaigns of non-selected ad accounts
      if (
        entity_type === "accounts" &&
        !this.selected_ad_accounts.find(({ id }) => account.id === id)
      ) this.selected_ad_campaigns = this.selected_ad_campaigns.filter(({ ad_account_id }) => ad_account_id !== account.id)

      this.get_ad_account_entities(fetched_entity_type)
    },
    remove_add(ad_index) {
      // If deleting last slide, just clear it
      if (this.new_ad.ad_slides.length === 1) this.new_ad.ad_slides = [{}]
      else this.new_ad.ad_slides = this.new_ad.ad_slides.filter((_, index) => index !== ad_index)
    },
    add_emoji({ native }) {
      const new_ad_message = this.new_ad.ad_message.split("")

      new_ad_message.splice(this.$refs.post_textarea.$el.selectionStart, 0, `${native} `)

      this.new_ad.ad_message = new_ad_message.join("")
    },
    add_media_files(files) {
      Array.from(files).forEach((file, index) => {
        try {
          this.update_loader()
          const reader = new FileReader()

          if (this.adding_new_slide) index = this.new_ad.ad_slides.length + index

          reader.addEventListener("load", async () => {
            if (!this.new_ad.ad_slides[index]) this.add_ad_slide(index)

            if (this.uploading_video) {
              const thumbnails = await getThumbnails(file);
              const video_thumb_reader = new FileReader();

              video_thumb_reader.onloadend = () => {
                this.$set(this.new_ad.ad_slides[index], "media", {
                  src: reader.result,
                  thumbnail: video_thumb_reader.result,
                  file: file,
                  size: file.size,
                  type: fb_media_file_types.videos,
                  format: file.type.replace("video/", "")
                })
                this.update_loader(false)
                this.uploading_video = false
              }

              video_thumb_reader.readAsDataURL(thumbnails[0].blob); 
            }
            else {
              const img = new Image();

              img.onload = () => {
                this.$set(this.new_ad.ad_slides[index], "media", {
                  src: reader.result,
                  file: file,
                  type: fb_media_file_types.photos,
                  size: file.size,
                  height: img.height,
                  width: img.width,
                  format: file.type.replace("image/", "")
                })
                this.update_loader(false)
              }

              img.src = reader.result;
            }
          }, false)

          reader.readAsDataURL(file)
        } catch (error) {
          console.log(error)
        }
      })
      this.adding_new_slide = false
      this.open_media_upload_modal = false
    },
    async select_entity(collection_data, product_data, direct_to_product_or_collection, selecting_collection, selecting_parent_collection) {
      const parent_collection = get_curly_brace_content(collection_data.body_html || "")
      const category = selecting_parent_collection ?
        parent_collection[1] :
        parent_collection ? `${parent_collection[1]}/${collection_data.handle}` : collection_data.handle
      const sub_category = (selecting_parent_collection || selecting_collection) ? "" : (product_data.buffer_id || product_data.id)
      const post_text = selecting_parent_collection ?
        url_enity_encoder.decode(parent_collection[1]) :
        get_correct_translation_value(selecting_collection ? collection_data : product_data, "title")
      const final_route = direct_to_product_or_collection ? 
          `/?${selecting_collection ? collection : product}=${
            selecting_collection ? collection_data.handle : (product_data.buffer_id || product_data.id)
          }` :
          `/${category}/${sub_category}`

      this.new_ad.ad_link = `https://${this.project_config.domain}/Products${final_route}`
      this.new_ad.ad_message = post_text

      if (product_data && product_data.images) {
        this.update_loader()
        // Upload only first 3 images
        const image_files = await Promise.all(product_data.images.slice(0, 3).map(async (image, index) => {
          const correct_image_src = get_image_src(image, image_dimension_names.desktop)
          const file_format = correct_image_src.split(/[#?]/)[0].split('.').pop().trim()

          this.add_ad_slide(index, { display_link: this.new_ad.ad_link })

          return await fetch(correct_image_src)
            .then(r => r.blob())
            .then(blobFile => new File(
              [blobFile],
              `image-${index}`,
              {
                type: `image/${file_format === "jpg" ? "jpeg" : file_format}`,
              }
            ))
        }))

        this.update_loader(false)

        this.add_media_files(image_files)
      }

      this.open_entity_selection_modal = false
    },
    add_ad_slide(new_index, slide_data = {}) {
      this.$set(this.new_ad.ad_slides, new_index === undefined ? this.new_ad.ad_slides.length : new_index, slide_data)
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_global-constants" as *;
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/admin" as *;
  @use "../../../../../styles/admin_image_upload.scss";
  @use "../../../../../../node_modules/vue-datetime/dist/vue-datetime.css";

  .social-media-ads {
    hr {
      border-color: $material-grey--light;
      border-style: solid;
    }

    &__photos {
      margin-bottom: $default-size;
    }

    &__link {
      margin-bottom: $double-default-size;

      a {
        display: block;
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__table admin-table {
      td {
        max-width: 400px;
        white-space: break-spaces;
        word-break: break-word;
      }

      .md-table-cell {
        span {
          margin-right: $half-default-size;
        }
        img {
          display: inline-block;
          max-width: 100px;
          max-height: 100px;
        }
      }
    }

    &__emoji-button {
      display: inline-block;
      position: relative;
    }

    &__sections {
      > .md-tabs-content {
        min-height: 100vh !important;
        overflow: visible;
      }
    }

    &__media-preview {
      position: relative;
      display: inline-block;
      margin: 0 $half-default-size $half-default-size 0;
      vertical-align: top;

      video {
        max-width: 200px;
        max-height: 200px;
        border-radius: $border-radius;
      }

      .image-upload__image {
        display: block;
        margin: 0;
        cursor: grab;

        &:active:hover {
          cursor: grabbing;
        }
      }

      .md-icon {
        min-width: $default-size;
        width: $default-size;
        height: $default-size;
        font-size: $default-size !important;
      }

      .md-icon-button {
        position: absolute;
        top: -12px;
        right: -15px;
        width: 30px;
        min-width: 30px;
        height: 30px;
        z-index: 1;
      }
    }

    &__actions {
      .md-icon-button {
        display: inline-block;
        height: 30px;
        width: 30px;
        min-width: 30px;
        border-radius: 0;

        .md-ripple {
          height: 30px;
          width: 30px;
          border-radius: 0;
        }

        .md-icon {
          height: 30px;
          width: 30px;
          font-size: 35px !important;
        }
      }

      .md-button {
        margin: $half-default-size $half-default-size 0 0;

        &:last-child {
          margin-right: 0;
          float: right;
        }
      }
    }

    &__errors-title {
      margin-top: 0;
      color: $material-red;
    }

    &__creation {
      display: grid;
      grid-template-columns: 2.5fr 1.5fr;
      column-gap: $double-default-size;

      @media (max-width: $tablet) {
        grid-template-columns: 1fr;
      }

      .md-has-textarea {
        margin-bottom: 0;
      }

      &-channels {
        .md-subheading, .md-title {
          margin-top: 0;
        }
      }

      .md-content {
        padding: $half-default-size $default-size !important;
        border-radius: $border-radius;
        margin-bottom: $default-size;
      }

      .md-gutter p {
        margin: 0 0 $half-default-size;
        color: $material-input-color;
      }

      &-top {
        display: grid;
        grid-template-columns: 80px calc(100% - 100px);
        column-gap: $default-size;
        align-items: center;

        @media (max-width: $mobile) {
          grid-template-columns: 60px calc(100% - 80px);
        }

        textarea {
          min-height: 50px !important;
        }

        & > div:first-child {
          display: flex;
          height: 100%;
          padding-right: $default-size;
          align-items: center;
          border-right: 2px solid $material-input-color;

          @media (max-width: $mobile) {
            .md-button {
              width: $icon-size !important;
              height: $icon-size !important;
            }

            padding-right: $half-default-size;
          }
        }

        .md-fab {
          background-color: $pure-white !important;
          margin: 0;
        }
      }
    }

    &__create-campaign {
      .md-field {
        min-width: 180px;
      }
    }

    &__ad-slide {
      position: relative;
      border-radius: $border-radius;
      padding-top: $half-default-size;

      .md-field {
        margin: 0 0 $half-default-size;
      }

      &s {
        margin-top: $double-default-size;

        .VueCarousel-slide {
          padding: $default-size $half-default-size;
        }

        &-new {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-right: 100px;
        }

        .VueCarousel-dot{
          margin-top: 0 !important;

          &-container {
            margin-top: 0 !important;
            margin-bottom: $default-size;
          }
        }
      }


      &-delete {
        position: absolute !important;
        right: -20px;
        top: -20px;
      }

      &-top {
        text-align: center;

        i {
          font-size: 40px !important;
        }
      }
    }

    &__clear {
      &-top {
        @media (max-width: $tablet) {
          display: none;
        }
      }
      &-bottom {
        @media (min-width: $tablet) {
          display: none;
        }
        @media (max-width: $tablet) {
          display: block;
        }
      }
    }

    .md-speed-dial {
      position: relative;

      &-content {
        position: absolute;
        top: calc(100% - 5px);
        left: 7px;
        z-index: 2;
      }
    }

    .vdatetime-input {
      padding: 5px 0;
      border-radius: $border-radius;
      text-align: center;
      border-color: $material-grey--darkest;
    }
  }
</style>
