<template>
  <section>
    <md-content class="md-elevation-3" v-if="ad_provider_errors.length">
      <p class="md-title social-media-ads__errors-title">{{ $translate("social_media_ads.facebook_errors") }}:</p>
      <span class="md-body-1">{{ $translate("social_media_ads.some_campaign_ads_not_creates") }}</span>
      <br/><br/>
      <div v-if="campaign_errors.length">
        <p class="md-body-2">{{ $translate("social_media_ads.campaign_errors") }}:</p>
        <div
          v-for="{ id, message, name } in campaign_errors"
          :key="id"
        >
          <b>{{ name }}:</b> {{ message }}<br/>
        </div>
      </div>
      <br/>
      <div v-if="ad_account_errors.length">
        <p class="md-body-2">{{ $translate("social_media_ads.ad_account_errors") }}:</p>
        <div
          v-for="{ id, message, name } in ad_account_errors"
          :key="id"
        >
          <b>{{ name }}:</b> {{ message }}<br/>
        </div>
      </div>
    </md-content>

    <md-content class="md-elevation-3">
      <div class="social-media-ads__creation-channels">
        <p class="md-title">
          {{ $translate("social_media_ads.ad_channels_sharing") }}
        </p>

        <div
          v-show="social_media_ad_providers.length"
          v-for="provider in social_media_ad_providers"
          :key="provider"
        >
          <p class="md-subheading">
            {{ $translate(`social_media_accounts.providers.${provider}`) }} {{ $translate("social_media_accounts.entity_types.ad_accounts") }}:
          </p>
          <div class="social-media__platforms">
            <md-content
              v-for="ad_account in get_correct_entities(provider)"
              :key="ad_account.id"
              class="social-media__page social-media__page--no-img md-elevation-3"
              :class="{ 'social-media__page--selected': selected_ad_accounts.find(({ id }) => id === ad_account.id) }"
              @click="$emit('select_ad_entity', ad_account, 'accounts', 'campaigns')"
            >
              <p>{{ ad_account.name }}</p>
            </md-content>
          </div>
        </div>
        <div v-if="!social_media_ad_providers.length">
          {{ $translate("social_media_ads.add_channels_message") }}: <router-link :to="`${selected_project}/Admin/social/accounts`">{{ $translate("here") }}</router-link>
        </div>

        <div
          v-for="(campaigns_obj, provider) in get_all_campaigns_by_origin"
          v-show="selected_ad_accounts.length"
          :key="`${provider}_campaigns`"
        >
          <hr/>
          <p class="md-subheading">
            {{ $translate(`social_media_accounts.providers.${provider}`) }} {{ $translate("social_media_accounts.entity_types.ad_campaigns") }}:
          </p>
          <div class="social-media__platforms">
            <div
              v-for="(campaigns, ad_account_id) in campaigns_obj"
              :key="ad_account_id"
            >
              <p class="md-body-1">
                <b>{{ (get_all_ad_accounts.find(({ id }) => id === ad_account_id) || {}).name }}</b> {{ $translate("social_media_accounts.entity_types.ad_campaigns") }}:
              </p>
              <md-content
                v-for="campaign in campaigns"
                :key="campaign.id"
                class="social-media__page social-media__page--no-img md-elevation-3"
                :class="{ 'social-media__page--selected': selected_ad_campaigns.find(({ id }) => id === campaign.id) }"
                @click="$emit('select_ad_entity', { ...campaign, ad_account_id }, 'campaigns', 'audiences')"
              >
                <p>{{ campaign.name }}</p>
              </md-content>
              <md-button
                class="md-icon-button md-primary md-raised"
                @click="$emit('open_campaign_creation_modal', ad_account_id)"
              >
                <md-icon>add</md-icon>
                <md-tooltip md-direction="top">{{ $translate("social_media_ads.create_new_campaign") }}</md-tooltip>
              </md-button>
            </div>
          </div>
        </div>
        
        <div v-show="selected_ad_accounts.length">
          <hr/>
          <p class="md-subheading">
            {{ $translate(`social_media_accounts.providers.${social_media_provider_names.fb}`) }}
            {{ $translate(`social_media_accounts.entity_types.pages`) }}
          </p>
          <div class="social-media__platforms">
            <md-content
              v-for="page in connected_fb_pages"
              :key="page.id"
              class="social-media__page md-elevation-3"
              :class="{ 'social-media__page--selected': page.id === ad_data.selected_ad_page.id }"
              @click="$emit('update_new_ad', 'selected_ad_page', page)"
            >
              <p>{{ page.name }}</p>
            </md-content>
          </div>
        </div>

        <div v-if="selected_ad_campaigns.length && Object.keys(fb_ad_audiences)">
          <br/>
          <hr/>
          <p class="md-subheading">{{ $translate("social_media_ads.audince_selection_title") }}:</p>
          <div class="md-layout md-gutter">
            <div
              v-for="account in selected_ad_accounts"
              :key="account.id"
              class="md-layout-item"
            >
              <md-field>
                <label><b>{{ account.name }}</b> {{ $translate("social_media_ads.audience_select") }}*</label>
                <md-select
                  v-model="ad_data.selected_audiences[account.id]"
                  :multiple="true"
                  :md-dense="true"
                  @md-selected="value => $emit('update_new_ad', 'selected_audiences', value, account.id)"
                >
                  <md-option
                    v-for="audience in fb_ad_audiences[account.id]"
                    :key="audience.id"
                    :value="audience.id"
                  >{{ audience.name }}</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
        </div>

        <md-button
          :disabled="!does_every_selected_add_account_have_an_audience || !can_user_magade_ads"
          class="md-raised md-primary"
          @click="$emit('publish_ad')"
        >
          {{ $translate("social_media_posts.publish") }}
        </md-button>
      </div>
    </md-content>
  </section>
</template>

<script>
import { mapState } from "vuex"
import { social_media_ad_providers, social_media_provider_names } from "../../../constants/social_media_constants"
import { SOCIAL_STORE, USER_STORE } from "../../../constants/others_constants"

export default {
  props: {
    get_all_ad_accounts: {
      required: true,
      type: Array
    },
    selected_ad_accounts: {
      required: true,
      type: Array
    },
    ad_provider_errors: {
      required: true,
      type: Array
    },
    selected_ad_campaigns: {
      required: true,
      type: Array
    },
    ad_data: {
      required: true,
      type: Object
    },
    can_user_magade_ads: {
      required: true,
      type: Boolean
    },
  },
  computed: {
    ...mapState(SOCIAL_STORE, [
      "connected_fb_ad_accounts",
      "connected_fb_pages",
      "fb_ad_campaigns",
      "fb_ad_audiences",
    ]),
    ...mapState(USER_STORE, ["selected_project"]),
    get_all_campaigns_by_origin() {
      return {
        [social_media_provider_names.fb]: Object
          .entries(this.fb_ad_campaigns)
          .filter(([key]) => this.selected_ad_accounts.find(({ id }) => key === id))
          .reduce((tot, [key, val]) => ({ ...tot, [key]: val }), {})
      }
    },
    campaign_errors() {
      return this.ad_provider_errors.filter(({ is_ad_account }) => !is_ad_account)
    },
    ad_account_errors() {
      return this.ad_provider_errors.filter(({ is_ad_account }) => is_ad_account)
    },
    does_every_selected_add_account_have_an_audience() {
      return Boolean(
        this.selected_ad_accounts.length &&
        this.selected_ad_accounts.every(({ id }) => this.ad_data.selected_audiences?.[id]?.length)
      )
    },
  },
  created() {
    this.social_media_ad_providers = social_media_ad_providers
    this.social_media_provider_names = social_media_provider_names
  },
  methods: {
    get_correct_entities(provider) {
      return this[`connected_${provider}_ad_accounts`] || []
    },
  }
}
</script>

<style lang="scss">
  @use "../../../../../../node_modules/vue-datetime/dist/vue-datetime.css";
</style>
